<template>
    <div class="pl-8 pr-8">
      <div class="mb-xl-margin-file-sign">
        <v-row class="mt-10">
          <v-col class="text-left" cols="6">
            <h1 class="reference__title">Crear nueva solicitud</h1>
          </v-col>
        </v-row>
        <v-card outlined class="reference__card px-8 mt-8" elevation="0">
          <processWorkforceRequest @dataWorkforceRequest="dataWorkforceRequest" />  
        </v-card>
      </div>
      <Alert
        :open="message.dialog"
        :text="message.sms"
        :title="message.title"
        :type="message.type"
        :redirect="message.redirect"
        @close="message.dialog = false"
      >
      </Alert>
      <v-overlay :value="loadingAll">
        <v-progress-circular :size="120" color="#466BE3" indeterminate>
          {{ $translate("general.loading") }}
        </v-progress-circular>
      </v-overlay>
    </div>
  </template>
  <script>
  import color from "@/mixins/color";
  import Api from "@/utils/api";
  import Alert from "@/components/Alert.vue";
  import { VueEditor } from "vue2-editor";
  import processWorkforceRequest from "./processWorkforceRequest.vue";
  export default {
    components: {
      Alert,
      VueEditor,
      processWorkforceRequest,
    },
    mixins: [color],
    data() {
      return {
        loading: {
          table: false,
        },
        modal: {
          info: false,
        },
        message: {
          dialog: false,
          title: "",
          sms: "",
          type: "",
          redirect: "",
        },
        loadingAll: false,
      };
    },
    methods: {
      getToken() {
        var token = localStorage.getItem("token");
        var tokenb64 = localStorage.getItem("tokenB64");
  
        this.token = tokenb64;
        var div = token.split(".");
        if (div.length >= 2) {
          this.infoToken = JSON.parse(atob(div[1]));
          var nameList = this.infoToken.fullName.split(" ");
  
          if (nameList.length > 1) {
            if (nameList[0] != "") {
              this.name = nameList[0];
            } else {
              this.name = nameList[1];
            }
          } else {
            this.name = "";
          }
          // console.log(JSON.parse(atob(div[1])));
        } else {
          this.infoToken = {
            document: "",
          };
        }
      },
      dataDewelopment(data) {
        this.create(data);
      },
      create(data) {
        this.loadingAll = true;
        Api.newDevelopment()
          .createDevelopment(this.token, data)
          .then((res) => {
            this.message.dialog = true;
            this.message.sms = res.data.message;
            this.message.title = "Excelente";
            this.message.type = "success";
          })
          .catch((error) => {
            this.message.dialog = true;
            this.message.sms = "Hemos tenido problemas con tu solicitud";
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          })
          .finally(() => {
            this.loadingAll = false;
            this.$root.$emit("procesoFinalizado");
          });
      },
    },
    created() {
      this.getToken();
    },
  };
  </script>
  <style>
  @import "../../../assets/css/main.less";
  
  .navidad-text {
    display: flex;
  }
  
  .reference__table .v-data-table-header {
    font-family: "RobotoRegular";
    background-color: #f2f4ff;
    font-style: normal;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 19px;
    color: #353535 I !important;
  }
  
  tr,
  th,
  span .reference__table .text-start .sortable {
    font-size: 16px !important;
  }
  
  .reference__card {
    border-radius: 15px !important;
  }
  
  .reference__title {
    font-family: "RobotoRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 59px;
    color: #353535;
  }
  
  .references__header {
    color: #353535 !important;
  }
  </style>
  